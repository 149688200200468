<template>
    <pagination title="Purchase Invoices" :details-open="viewUser" :response="response" :entities="entities" :filterAction="filterAction" header-colour-class="bg-transparent" :withAltViewIcon="true" @altViewClicked="altViewClicked" activeClass="bg-v3-gray-50 bg-opacity-20">
        <template v-slot:additional-buttons>
            <slot name="additional-buttons"></slot>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">Reference
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold" :class="{'hidden': viewUser}">Item</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Invoice PDF</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold" :class="{'hidden': viewUser}">Net</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold" :class="{'hidden': viewUser}">VAT</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Gross</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Uploaded</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Parent Items</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Sales Invoice</th>

        </template>
        <template v-slot:items>
            <tr v-for="entity in entities" class="cursor-pointer">
                <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                    {{entity.entity.properties.get('reference')}}
                </td>
                <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                    {{entity.entity.properties.get('entity_type')}}
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">
                    <download-pdf-icon-with-link :url="entity.entity.properties.get('file')"></download-pdf-icon-with-link>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm" :class="{'hidden': viewUser}">
                    {{ entity.entity.properties.get('net_value') }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm" :class="{'hidden': viewUser}">
                    {{ entity.entity.properties.get('vat_value') }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm" :class="{'hidden': viewUser}">
                    {{ entity.entity.properties.get('gross_value') }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm" :class="{'hidden': viewUser}">
                    <template v-if="entity.entity.properties.get('isUploaded')">
                        <svg class="w-6 h-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                    </template>
                    <template v-else>
                        <svg class="w-6 h-6 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                    </template>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm" >
                    <a v-if="entity.entity.properties.get('entity_type') === 'order_invoice_in'" class="underline hover:no-underline" :href="`/orders/${entity.entity.properties.get('parent_uuid')}`">View parent item</a>
                    <a v-else-if="entity.entity.properties.get('entity_type') === 'rental_item_invoice_in'" class="underline hover:no-underline" :href="`/rental-items/${entity.entity.properties.get('parent_uuid')}`">View parent item</a>
                    <a v-else-if="entity.entity.properties.get('entity_type') === 'subscription'" class="underline hover:no-underline" :href="`/subscriptions/${entity.entity.properties.get('parent_uuid')}`">View parent item</a>

                </td>


                <td class="whitespace-nowrap px-3 py-4 text-sm" :class="{'hidden': viewUser}">
                    <a v-if="entity.entity.properties.get('linked_sales_invoice_uuid')" class="underline hover:no-underline" :href="`/invoices/${entity.entity.properties.get('linked_sales_invoice_uuid')}`">{{entity.entity.properties.get('linked_sales_invoice_reference')}} - {{entity.entity.properties.get('linked_sales_invoice_status')}}</a>
                </td>
            </tr>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import axios from "axios";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import HrSinglePersonPagination from "@/v3/components/pagination/HrSinglePersonPagination.vue";
import StatusBadge from "@/components/StatusBadge.vue";
import FlagOrderAction from "@/v2/components/FlagOrderAction.vue";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import Siren from "super-siren";
import DownloadPdfIconWithLink from "@/components/DownloadPdfIconWithLink.vue";
let config = {
    rounding: Math.floor
}
dayjs.extend(duration);
dayjs.extend(relativeTime, config);
export default {
  name: 'PurchaseInvoicesPagination',
    data(){
      return {
        viewUser: false,
          details: null,
          detailsOpen: false
      }
    },
    components: {DownloadPdfIconWithLink, FlagOrderAction, StatusBadge, HrSinglePersonPagination, Loading, Pagination},
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser",
        }),
        initials() {
            if (this.details && this.details.properties['name']) {
                return this.details.properties['name']
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        },
        filterAction(){
            return this.response.body.actions.filter(action => action.name === 'filter').first();
        }
    },
  props: {
    entities: {},
      response: {
        type: Object,
          default: null
      }
  },
    methods:{
      setDetailsOpen(entity){
          this.viewUser = true;
      },
      openDetails(entity){
          this.$emit('setLoading');
          this.details = false;
          this.viewUser = true;
          this.getDetails(entity);
      },
        getDetails(entity){
            let selfLink = entity.entity.links.filter(link => link.rels.contains('self')).first().href;
            console.log(selfLink);
            let parts = selfLink.split('/api');
            Siren.Client.addHeader(
                "Authorization",
                `Bearer ${this.accessToken}`
            );
            Siren.get(process.env.VUE_APP_API_URL + '/api' + parts[1]).then(response => {
                this.$emit('setDetails', response.body);
            }).catch(function(){

            });
        },
        altViewClicked(){
            this.$emit('altViewClicked');
            this.viewUser = false;
        }
    }
}
</script>
